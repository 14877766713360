<template>
  <contentCard titleText="">
    <div class="result-box">
      <div class="result-img" :class="$route.query.success === 'true' ? 'success' : 'fail'"></div>
      <div v-if="$route.query.success === 'true'" class="result-text success">下单成功，社区将在约定时间积极为您备餐</div>
      <div v-if="$route.query.success === 'false'" class="result-text fail">
        下单失败 <span v-if="$route.query.msg">，</span>{{ $route.query.msg }}
      </div>
    </div>
    <div class="handle-box">
      <div class="handle jxdc" @click="jxdcOnClick">继续点餐</div>
      <div v-if="$route.query.success === 'true'" class="handle ckddxq" @click="ckddxqOnClick">查看订单详情</div>
      <div v-if="$route.query.success === 'false'" class="handle fhsy" @click="fhsyOnClick">返回首页</div>
    </div>
  </contentCard>
</template>

<script>
import storage from '../utils/storage.js';
import contentCard from '../components/contentCard.vue';

export default {
  name: 'ytjEldCanPlaceOrderResult',
  components: {
    contentCard
  },
  methods: {
    jxdcOnClick() {
      this.$router.replace({
        name: 'ytjEldCanMealsList'
      });
    },
    ckddxqOnClick() {
      this.$router.replace({
        name: 'ytjEldCanMyOrderDetail',
        query: { id: this.$route.query.id }
      });
    },
    fhsyOnClick() {
      if (window.sys && window.sys.nativeGet) {
        storage.remove();
      }
      storage.remove();
      this.$router.replace({
        name: 'homePage'
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .content-body {
  padding: 0px 40px 40px 40px;
}
.result-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 50px);
  .result-img {
    box-sizing: border-box;
    width: 330px;
    height: 270px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    &.success {
      background-image: url('./img/result_success.png');
    }
    &.fail {
      background-image: url('./img/result_fail.png');
    }
  }
  .result-text {
    font-size: 24px;
    line-height: 33px;
    color: #1874fd;
    margin-top: 20px;
    &.fail {
      color: #1874fd;
    }
  }
}
.handle-box {
  box-sizing: border-box;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .handle {
    width: 240px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    &.jxdc {
      background-color: #1874fd;
      color: #ffffff;
    }
    &.ckddxq,
    &.fhsy {
      border: 1px solid #1874fd;
      color: #1874fd;
    }
  }
}
</style>
